
import React from "react";
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg";
import LeftRightBox from "../../components/LeftRightBox/LeftRightBox";
import MainLayout from "../../layouts/MainLayout";
import { Link } from "gatsby";
import "./meet-us.scss";

const MeetUs = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("meet-us:metaTitle")}>
      <>
        <HeroImg customClass="meet-us-hero" subtitle={t("meet-us:heroSubtitle")} title={t("meet-us:heroTitle")} backgroundImage="bg/meet-us-hero.jpg"></HeroImg>

        <div className="meet-us">

          <div className="divider"></div>

          <div className="meet-us-section-two">

            <LeftRightBox>
              {<img src="/img/meet-us-one.jpg" alt="meet-us-one" />}
              {<div className="text-wrap">
                <h3 className="box-two">{t("meet-us:sectionOneTitle")}</h3>
                <p className="box">{t("meet-us:sectionOneText")}</p>
                <Link to={t("routes:interestingFacts")}><button className="btn-white">{t("common:btnMore")}</button></Link>
              </div>}
            </LeftRightBox>

            <LeftRightBox customClass="reverse">
              {<div className="text-wrap">
                <h3 className="box-two">{t("meet-us:sectionTwoTitle")}</h3>
                <p className="box">{t("meet-us:sectionTwoText")}</p>
                <Link to={t("routes:origin")}><button className="btn-white">{t("common:btnMore")}</button></Link>
              </div>}
              {<img src="/img/meet-us-two.jpg" alt="meet-us-two" />}
            </LeftRightBox>

            <LeftRightBox>
              {<img src="/img/meet-us-three.jpg" alt="meet-us-three" />}
              {<div className="text-wrap">
                <h3 className="box-two">{t("meet-us:sectionThreeTitle")}</h3>
                <p className="box">{t("meet-us:sectionThreeText")}</p>
                <Link to={t("routes:howToDrink")}><button className="btn-white">{t("common:btnMore")}</button></Link>
              </div>}
            </LeftRightBox>

            <LeftRightBox customClass="reverse">
              {<div className="text-wrap">
                <h3 className="box-two">{t("meet-us:sectionFourTitle")}</h3>
                <p className="box">{t("meet-us:sectionFourText")}</p>
                <Link to={t("routes:pairingWithFood")}><button className="btn-white">{t("common:btnMore")}</button></Link>
              </div>}
              {<img src="/img/meet-us-four.jpg" alt="meet-us-four" />}
            </LeftRightBox>
          </div>
        </div>
      </>
    </MainLayout>
  )
}

export default MeetUs;